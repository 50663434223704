// React - Redux
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// tools
import Icon from "utils/icon";
import styled from "styled-components";
import { useIntl } from "react-intl";
import messages from "containers/Login/messages";
// components
import Spinner from "components/Spinner/Spinner";
// actions
import { checkAuthToken } from "actions/login";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

const AuthValidation = ({ token }) => {

  const { closePage } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const checkAuthTokenFunc = (token, intl, messages) => dispatch(checkAuthToken(token, intl, messages));
  const intl = useIntl();

  useEffect(() => {
    checkAuthTokenFunc(token, intl, messages);
  }, []);

  useEffect(() => {
    if (closePage) {
      window.close();
    }
  }, [closePage]);

  return (
    <Container>
      <Spinner>
        <Icon icon="LoadingHalf" spin size="2x"/>
      </Spinner>
    </Container>
  );
};

export default AuthValidation;